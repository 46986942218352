<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="tracks-view">
                    <!-- tracks view media object start -->
                    <div class="row">
                        <div class="col-12 col-sm-7">
                        <div class="media mb-2">
                            <a class="mr-1" href="#">
                            <img v-if="creator[0].profile_photo_url" :src="creator[0].profile_photo_url" alt="tracks view avatar" class="tracks-avatar-shadow rounded-circle" height="64" width="64">
                            <img v-else src="/images/nopic.png" alt="tracks view avatar" class="tracks-avatar-shadow rounded-circle" height="64" width="64">
                            </a>
                            <div class="media-body pt-25">
                            <h4 class="media-heading"><span class="tracks-view-name"></span></h4>
                            <!-- <span>More info:</span>
                            <span class="tracks-view-id">{{track[0].description}}</span> -->
                            </div>
                        </div>
                        </div>
                        <span>
                            <button  v-if="!creator[0].email_verified_at" @click.prevent="verifyUser()" class="btn btn-success">Verify</button>
                            <button  v-else class="btn btn-primary" @click.prevent="unVerifyUser()">Unverify</button>
                        </span>
                    </div>
                    <!-- tracks view media object ends -->
                    <!-- tracks view card data start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                            <div class="col-12 col-md-8">
                                <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Registered:</td>
                                            <td>{{ creator[0].created_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Latest Activity:</td>
                                            <td class="users-view-latest-activity">{{ creator[0].updated_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Role:</td>
                                            <td class="users-view-role">Producer</td>
                                        </tr>

                                        <tr>
                                            <td>Verification Status:</td>
                                            <td><span v-if="creator[0].email_verified_at" class="badge badge-success users-view-status">verified</span>
                                                <span v-else class="badge badge-danger users-view-status">Not verified</span>
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>
                            </div>

                            </div>
                        </div>
                        </div>
                    </div>
                    <!-- tracks view card data ends -->
                    <!-- tracks view card details start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">

                                    <h5 class="mb-1"><i class="feather icon-info"></i>Basic Details</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-10">
                                        <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Number of Tracks:</td>
                                            <td>{{ creator[0].tracks }}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Sound Kits:</td>
                                            <td>{{ creator[0].soundKits }}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Albums:</td>
                                            <td class="users-view-role">{{ creator[0].albums}}</td>
                                        </tr>

                                        <tr>
                                            <td>Number of Followers:</td>
                                            <td class="users-view-role">{{ creator[0].followers }}</td>
                                        </tr>


                                        </tbody>
                                    </table>
                                    </div>
                                    </div>

                        </div>
                        </div>
                    </div>
                    <!-- tracks view card details ends -->

                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">

                            <h5 class="mb-1"><i class="feather icon-info"></i> Personal Info</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-12">
                                        <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Name:</td>
                                        <td class="users-view-name"> {{
                                                creator[0].name
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Username:</td>
                                        <td class="users-view-name"> {{
                                                creator[0].user_name
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>E-mail:</td>
                                        <td class="users-view-email">{{ creator[0].email }}</td>
                                    </tr>
                                    <tr>
                                        <td>Contact:</td>
                                        <td class="users-view-email">{{creator[0].phone_number }}</td>
                                    </tr>
                                    <tr>
                                        <td>Country:</td>
                                        <td class="users-view-email">{{creator[0].country_code[0].name }}</td>
                                    </tr>

                                    </tbody>
                                </table>
                                    </div>
                                    </div>

                        </div>
                        </div>
                    </div>


            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue';

export default {
    metaInfo: {title: 'Show Producer'},
    props: ['creator'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.creators.index",
            linkPage: "All Producer",
            currentPage: "View Producer",
           },
           isActive: false
        }
    },
    methods: {

        verifyUser(){
            this.creator[0].email_verified_at = new Date()
            try {

                let updatedData = {
                    email_verified_at: this.creator[0].email_verified_at,
                }
                this.$inertia.put(route('admin.creators.verifyUser', this.creator[0].id), updatedData)
            } catch (e) {
                console.log(e);
            }
        },

        unVerifyUser(){
            this.creator[0].email_verified_at = ''
            try {

                let updatedData = {
                    email_verified_at: this.creator[0].email_verified_at,
                }
                this.$inertia.put(route('admin.creators.unVerifyUser', this.creator[0].id), updatedData)
            } catch (e) {
                console.log(e);
            }
        }
    },



}
</script>

<style scoped>

</style>
