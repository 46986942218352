<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div  class="card-body">

                    <form
                        @submit.prevent="submit"
                     >
                        <div class="row">
                            <div class="col-12">
                                <validation-errors></validation-errors>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <div class="controls">
                                    <label>Name of Plan</label>
                                    <input
                                        type="text"
                                        v-model="planData.name"
                                        class="form-control"
                                        placeholder="Enter of Plan"
                                    />
                                </div>
                            </div>
<!--
                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Features</label>
                                    <textarea
                                        class="form-control"
                                        name="meta_description"
                                        id="meta_description"
                                        cols="30"
                                        rows="2`"
                                        v-model="plans.features"
                                        placeholder="Feautures"
                                    >
                                    </textarea>
                                </div>
                            </div> -->

                        </div>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Price</label>
                                    <input
                                        type="number"
                                        v-model="planData.price"
                                        class="form-control"
                                        placeholder="Price"
                                    />
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Duration</label>
                                    <select
                                        v-model="planData.duration"
                                        id=""
                                        class="form-control"
                                    >
                                        <option value="" selected disabled>
                                            Select Duration
                                        </option>
                                        <option value="1">Monthly</option>
                                        <!-- <option value="12">Yearly</option> -->
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Discount(Yearly)</label>
                                    <input
                                        type="number"
                                        v-model="planData.discount"
                                        class="form-control"
                                        placeholder="Example 2%"
                                    />
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Status</label>
                                    <select
                                        v-model="planData.status"
                                        id=""
                                        class="form-control"
                                    >
                                        <option value="" selected>
                                            Select Status
                                        </option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="form-group col-md-3">
                                <div class="controls">
                                    <label>Tracks</label>
                                    <input
                                        type="text"
                                        v-model="planData.allowed_total_tracks"
                                        class="form-control"
                                     />
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="controls">
                                    <label>Albums</label>
                                    <input
                                        type="text"
                                        v-model="planData.allowed_total_albums"
                                        class="form-control"
                                     />
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="controls">
                                    <label>SoundKit</label>
                                    <input
                                        type="text"
                                        v-model="planData.allowed_total_sound_kits"
                                        class="form-control"
                                     />
                                </div>
                            </div>

                            <div class="form-group col-md-3">
                                <div class="controls">
                                    <label>Playlist</label>
                                    <input
                                        type="text"
                                        v-model="planData.allowed_total_playlists"
                                        class="form-control"
                                     />
                                </div>
                            </div>
                        </div>

                        <!-- </div> -->

                        <div
                            class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1"
                        >
                            <button
                                type="submit"
                                class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ValidationErrors from "../../Shared/ValidationErrors";
 import { Link } from '@inertiajs/inertia-vue';



export default {
    metaInfo: {title: 'Create Plan'},
    name: 'CreatePlan',
    layout: Layout,
    props: ['plan'],
    components: {
        BreadCrumbs,
        ValidationErrors,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.plans.index",
            linkPage: "All Plan",
            currentPage: "Edit Plan",
           },
           planData: {

            },


        }
    },
    methods: {
        submit(){
            this.$inertia.put(route('admin.plans.update',this.plan),this.planData,{
                 preserveScroll: true,
                });
        },


    },

    mounted(){
        this.planData = this.plan[0]
    }

 }

</script>
